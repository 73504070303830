<template>
  <v-card>
    <v-tabs vertical>
      <v-subheader>Valores referencia</v-subheader>
      <v-divider></v-divider>
      <v-tab>
        <v-icon left size="medium">fas fa-user-check</v-icon>
        Tipo de desarmonía
      </v-tab>
      <v-tab>
        <v-icon left size="medium">fas fa-user-tag</v-icon>
        Tipo de recepción
      </v-tab>
      <v-tab>
        <v-icon left size="medium">fas fa-user-tag</v-icon>
        Lugar corrección
      </v-tab>
      <v-tab>
        <v-icon left size="medium">fas fa-user-tag</v-icon>
        Tipo corrección 
      </v-tab>
      <v-tab>
        <v-icon left size="medium">fas fa-user-tag</v-icon>
        Tipo seguimiento
      </v-tab>
      <v-tab>
        <v-icon left size="medium">fas fa-user-tag</v-icon>
        Encabezados de documentos
      </v-tab>
      <v-tab-item>
        <v-card flat>
          <v-card-text>
            <ListDisharmonyType/>
          </v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-card flat>
          <v-card-text>
            <ListReceptionType/>
          </v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-card flat>
          <v-card-text>
            <ListPlaceCorrection/>
          </v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-card flat>
          <v-card-text>
            <ListCorrectionType />
          </v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-card flat>
          <v-card-text>
            <Tracking />
          </v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-card flat>
          <v-card-text>
            <header-lines></header-lines>
          </v-card-text>
        </v-card>
      </v-tab-item>
    </v-tabs>
  </v-card>
</template>
<script>
import ListDisharmonyType from "@/views/SelfJustice/ListDisharmonyType";
import ListReceptionType from "@/views/SelfJustice/components/ValueReferences/ReceptionType/ListReceptionType";
import ListPlaceCorrection from "@/views/SelfJustice/components/ValueReferences/PlaceCorrection/ListPlaceCorrection";
import ListCorrectionType from "@/views/SelfJustice/components/ValueReferences/CorrectionType/ListCorrectionType";
import Tracking from "@/views/SelfJustice/components/Tracking";
import HeaderLines from "@/views/SelfJustice/components/ValueReferences/HeaderLines/HeaderLines";

export default {
  components: {
    HeaderLines,
    ListDisharmonyType,
    ListReceptionType,
    ListPlaceCorrection,
    ListCorrectionType,
    Tracking,
  },
  data() {
    return {};
  },
  props : {
    isFemale: {
      type: String,
      default: 'false'
    }
  },
  computed: {},
  beforeMount() {},
};
</script>
<style scoped>
.v-tab {
  justify-content: start !important;
}
</style>
